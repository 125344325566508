<template>
    <v-bottom-sheet v-model="shows" fullscreen content-class="mw-100 h-100" max-width="100%" width="100%">
        <template #activator="bind">
            <slot name="activator" v-bind="bind" />
        </template>
        <v-btn absolute top right icon text color="white" class="v-size--xx-large" @click="close">
            <icon-close />
        </v-btn>

        <v-expand-transition>
            <v-responsive v-show="showsVideo" max-width="1032" :aspect-ratio="1032 / 580">
                <youtube v-if="videoId" v-bind="{ videoId }" fitParent @ready="showsVideo = true" />
                <video ref="video-player" class="video-js vjs-theme-city" width="1032" controls />
            </v-responsive>
        </v-expand-transition>
        <v-fade-transition hide-on-leave>
            <v-progress-circular v-show="!showsVideo" width="5" size="200" color="white" indeterminate />
        </v-fade-transition>
    </v-bottom-sheet>
</template>

<script>
import urlParser from "js-video-url-parser";

import videojs from "video.js";
import "video.js/dist/video-js.css";
import "@videojs/themes/dist/city/index.css";

import IconClose from "@/components/client/icons/icon-close.vue";

export default {
    components: {
        IconClose,
    },
    props: {
        board: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        showsVideo: false,
    }),
    computed: {
        videoId() {
            if (this.board?.meta?.youtube) return urlParser.parse(this.board.meta.youtube)?.id;
        },
        fileUrl() {
            if (this.board?.files?.[0]) return this.board.files[0].url;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            this.init();
        },
    },
    methods: {
        init() {
            if (this.shows) if (this.fileUrl) this.setVideo();
        },
        close() {
            this.shows = false;
        },

        setVideo() {
            this.showsVideo = true;

            this.$nextTick(() => {
                this.player = videojs(this.$refs["video-player"], {
                    loop: false,
                    fluid: true,
                    autoplay: false,
                    controls: true,
                    sources: [{ src: this.fileUrl }],
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-bottom-sheet {
        background-color: rgba(0, 0, 0, 0.8) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .v-btn--absolute.v-btn--top,
        .v-btn--fixed.v-btn--top {
            top: 46px;
        }
        .v-btn--absolute.v-btn--right,
        .v-btn--fixed.v-btn--right {
            right: 46px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
