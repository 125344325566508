<template>
    <v-sheet>
        <v-card v-bind="{ href }" target="_blank" rounded style="overflow: hidden" @click="download">
            <v-img :src="item?.thumb?.url" :aspect-ratio="282 / 400" class="w-100 border rounded" />
        </v-card>
        <div class="pt-12px pt-md-20px">
            <v-chip v-if="code == 'catalogue' && item?.category" x-small outlined color="grey lighten-3" text-color="grey" class="mb-4px">{{ item?.category?.name }}</v-chip>
            <p class="page-text font-weight-medium text-truncate">{{ item.name }}</p>
            <v-btn v-bind="{ href }" download block color="primary" class="v-size--xx-large mw-100 mt-12px mt-md-20px" @click="download">
                <span v-if="code == 'catalogue'">카탈로그 다운로드하기</span>
                <span v-else-if="code == 'software'">다운로드</span>
                <icon-download class="ml-lg-10px" />
            </v-btn>
        </div>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { initCatalogue, USER_LOG_TYPES } from "@/assets/variables";

import IconDownload from "@/components/client/icons/icon-download.vue";

export default {
    components: {
        IconDownload,
    },
    props: {
        item: { type: Object, default: initCatalogue },
    },
    computed: {
        code() {
            return this.item?.code || "catalogue";
        },
        url() {
            return this.item?.file?.url;
        },
        href() {
            if (this.code != "software") return this.url;
        },
    },
    methods: {
        async download() {
            if (this.url && this.code == "software") {
                await api.v1.me.logs.post({
                    type: USER_LOG_TYPES.SOFTWARE_DOWNLOAD.value,
                    _software: this.item?._id,
                });
                await api.downloadMemberResource(this.url);
            }
        },
    },
};
</script>
