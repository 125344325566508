var render = function render(){
  var _vm$item, _vm$item$thumb, _vm$item2, _vm$item3, _vm$item3$category;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-card', _vm._b({
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "target": "_blank",
      "rounded": ""
    },
    on: {
      "click": _vm.download
    }
  }, 'v-card', {
    href: _vm.href
  }, false), [_c('v-img', {
    staticClass: "w-100 border rounded",
    attrs: {
      "src": (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : (_vm$item$thumb = _vm$item.thumb) === null || _vm$item$thumb === void 0 ? void 0 : _vm$item$thumb.url,
      "aspect-ratio": 282 / 400
    }
  })], 1), _c('div', {
    staticClass: "pt-12px pt-md-20px"
  }, [_vm.code == 'catalogue' && (_vm$item2 = _vm.item) !== null && _vm$item2 !== void 0 && _vm$item2.category ? _c('v-chip', {
    staticClass: "mb-4px",
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey lighten-3",
      "text-color": "grey"
    }
  }, [_vm._v(_vm._s((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : (_vm$item3$category = _vm$item3.category) === null || _vm$item3$category === void 0 ? void 0 : _vm$item3$category.name))]) : _vm._e(), _c('p', {
    staticClass: "page-text font-weight-medium text-truncate"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large mw-100 mt-12px mt-md-20px",
    attrs: {
      "download": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.download
    }
  }, 'v-btn', {
    href: _vm.href
  }, false), [_vm.code == 'catalogue' ? _c('span', [_vm._v("카탈로그 다운로드하기")]) : _vm.code == 'software' ? _c('span', [_vm._v("다운로드")]) : _vm._e(), _c('icon-download', {
    staticClass: "ml-lg-10px"
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }